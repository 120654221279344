import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage as Image, getImage } from 'gatsby-plugin-image';
import * as React from "react";

import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import Link from '../components/Link';
import List from '../components/List';
import Seo from '../components/Seo';

import NopSource from '../images/partners/nop.svg';

const InformationalPartnersPage = ({ pageContext }) => {

  const data = useStaticQuery(graphql`
    query {
      oschadPhoto: file(relativePath: {eq: "partners/oschad-info.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            height: 48
            placeholder: NONE
          )
        }
      }
    }
  `);

  return (
    <>
      <Seo
        title="Презентація сайту folklore.com.ua"
        descriptions="Пропозиції щодо реклами"
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <h1 className="typo-h1 max-w-xxl lowercase mt-4">
            Інформаційні партнери
          </h1>
        </div>
      </div>
      <div className="container-lg my-12 md:my-24">
        <div className="row items-center">
          <div className="col-full md:col-5">
            <h2 className="typo-h2 mb-4">
              Not only pot
            </h2>
            <p className="typo-body">
              Український бренд виробів ручної роботи для створення затишку у вашому просторі.
            </p>
            <div className="mt-4">
              <Button to="https://notonlypot.com/" size="xxs" variant="cta" className="typo-body" rel={null}>
                Not only pot
                <span className="ml-2">
                  &rarr;
                </span>
              </Button>
            </div>
          </div>
          <div className="mt-8 md:mt-0 col-full md:col-5 md:ml-2/12">
            <div className="bg-gray-100 py-10 px-4">
              <img src={NopSource} alt="Ornament" className="h-32 mx-auto"/>
            </div>
          </div>
        </div>
        <div className="row items-center mt-12 md:mt-24">
          <div className="mt-8 md:mt-0 order-2 md:order-1 col-full md:col-5">
            <div className="bg-gray-100 py-20 px-4 text-center">
              <Image
                image={getImage(data.oschadPhoto)}
                alt="Ощад інфо"
                className="h-12 mx-auto"
              />
            </div>
          </div>
          <div className="order-1 md:order-2 mt-8 md:mt-0 col-full md:col-5 md:ml-2/12">
            <h2 className="typo-h2 mb-4">
              Ощад інфо
            </h2>
            <p className="typo-body">
              Неофіційний інформаційний ресурс про послуги "Ощадбанк"
            </p>
            <div className="mt-4">
              <Button to="https://oschad.info/" size="xxs" variant="cta" className="typo-body" rel={null}>
                Ощад інфо
                <span className="ml-2">
                  &rarr;
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationalPartnersPage;
